'use strict'

const _ = require('lodash')
const coreUtils = require('santa-core-utils')
const dataUtils = require('../helpers/dataUtils')


const createRelationToCompIdMap = (dataMap, queryName, structure) => {
    const allComps = coreUtils.dataUtils.getAllCompsInStructure(structure, false, x => _.has(x, queryName))
    const res = {}
    _.forEach(allComps, comp => {
        const dataQuery = dataUtils.stripHashIfExists(comp[queryName])
        const dataItem = _.get(dataMap, dataQuery)
        if (dataUtils.refArray.isRefArray(dataItem)) {
            const values = dataUtils.refArray.extractValuesWithoutHash(dataItem)
            _.forEach(values, refId => {
                if (dataUtils.breakpointRelation.isBreakpointRelation(dataMap[refId])) {
                    res[refId] = comp.id
                }
            })
        }
    })
    return res
}

const fixBreakpointRelationItem = (item, relationToCompId) => {
    if (dataUtils.breakpointRelation.isBreakpointRelation(item)) {
        item.type = 'VariantRelation'
        item.to = item.ref
        item.from = `#${relationToCompId[item.id]}`
        delete item.ref
        if (item.breakpoint) {
            item.variants = [item.breakpoint]
            delete item.breakpoint
        }
    }
}

const fixComponentBreakpointQuery = (comp, variantsData) => {
    const breakpointId = dataUtils.stripHashIfExists(comp.breakpointsQuery)
    const breakpointsData = variantsData[breakpointId]
    if (breakpointsData) {
        breakpointsData.componentId = comp.id
    }
    if (comp.breakpointsQuery) {
        comp.breakpointVariantsQuery = dataUtils.stripHashIfExists(comp.breakpointsQuery)
        delete comp.breakpointsQuery
    }
}

const fixComponents = (components, variantsData) => {
    _.forEach(components, comp => {
        fixComponentBreakpointQuery(comp, variantsData)
    })
}

/**
 * Fixes:
 * - move all items from breakpoints_data to variants_data
 * - change relationItem type from BreakpointRelation to VariantRelation in theme_data and layout_data
 * - change condition property for BreakpointRelation items from breakpoint to variants in theme_data and layout_data
 * - change ref property for BreakpointRelation items ('ref' -> 'to') in theme_data and layout_data
 * */
const fixPageData = page => {
    const {data, structure} = page
    if (data.breakpoints_data) {
        data.variants_data = data.breakpoints_data
        delete data.breakpoints_data
    }
    if (data.layout_data) {
        const relationToCompId = createRelationToCompIdMap(data.layout_data, 'layoutQuery', structure)
        data.layout_data = _.mapValues(data.layout_data, item => {
            fixBreakpointRelationItem(item, relationToCompId)
            return item
        })
    }
    if (data.theme_data) {
        const relationToCompId = createRelationToCompIdMap(data.theme_data, 'styleId', structure)
        data.theme_data = _.mapValues(data.theme_data, item => {
            fixBreakpointRelationItem(item, relationToCompId)
            return item
        })
    }
    return data
}

/**
 * @exports utils/dataFixer/plugins/migrateResponsiveToVariantsModels
 * @type {{exec: function}}
 */
module.exports = {
    exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewer, rendererModel, magicObject) {
        const {isExperimentOpen} = magicObject
        if (isExperimentOpen('bv_migrateResponsiveToVariantsModels') || isExperimentOpen('dm_migrateResponsiveToVariantsModels')) {
            if (_.isEmpty(pageJson.data.breakpoints_data)) {
                return // don't need to run if data already migrated
            }
            const components = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, false)
            fixPageData(pageJson)
            const variantsData = pageJson.data.variants_data || {}
            fixComponents(components, variantsData)
        }
    }
}
